<template>
    <div class="businessCardMember">
        <div class="left-box">
            <div class="card-category-box">
                <div class="category-title"><i class="iconfont icon-7-4kaleixing"></i>卡类</div>
                <div class="card-box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.card-li',marginH:20}">
                    <div class="card-li" 
                        :class="{selected:item==selectCard}" 
                        v-for="item in crmCardKind" :key="item"
                        @click="clickCard(item)"
                        >{{item.CardKind_Name}}<i class="iconfont icon-gou1"></i></div>
                </div>
            </div>
            <div class="item-operation">
                <div class="float-right">
                   <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                   <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                </div>
            </div>
        </div>
        <div class="right-box">
            <div class="card-info">
                <div class="tr-li">
                    <div class="lable-txt">外卡号：</div>
                    <div class="from-input"><input type="text" maxlength="20" v-model="cardNo" placeholder="卡号可以为字母或数字，最多20位" 
                        @blur="inputBlur"  
                        @focus="inputFocus" ref="cardNo" 
                        :disabled="isSNRInCardNo"
                    /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">内卡号：</div>
                    <div class="from-input"><input type="text" maxlength="20" :disabled="selectCard?.CardType==2" v-model="snr"
                        @blur="inputBlur"  
                        @focus="inputFocus" ref="snr" 
                     /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt"></div>
                    <div class="from-input">
                        <label><input type="checkbox" v-model="isSNRInCardNo"/>将内卡号作为外卡号</label>
                        <div style="float:right">
                            连续制卡跳过指定数字
                            <span class="num"><input type="text" maxlength="1" v-model="num1" @blur="inputBlur"  @focus="inputFocus" ref="num1"/></span>
                            <span class="num"><input type="text" maxlength="1" v-model="num2" @blur="inputBlur"  @focus="inputFocus" ref="num2"/></span>
                            <span class="num"><input type="text" maxlength="1" v-model="num3" @blur="inputBlur"  @focus="inputFocus" ref="num3"/></span>
                        </div>
                    </div>
                </div>
                <div class="keyboard ">
                    <div class="key-li tr-li" @mousedown="mousedownKeyBoard">
                        <span @click="cheageKeyBoard(0)">0</span><span @click="cheageKeyBoard(1)">1</span><span @click="cheageKeyBoard(2)">2</span><span @click="cheageKeyBoard(3)">3</span>
                        <span @click="cheageKeyBoard(4)">4</span><span @click="cheageKeyBoard(5)">5</span><span @click="cheageKeyBoard(6)">6</span>
                        <span @click="cheageKeyBoard(7)">7</span><span @click="cheageKeyBoard(8)">8</span><span @click="cheageKeyBoard(9)">9</span>
                        <span @click="cheageKeyBoard(-1)"><i class="iconfont icon-zuojiantou"></i></span>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt" style="min-width:115px">连续制卡间隔时间:</div>
                    <div class="from-input">
                        <el-slider v-model="interval" :max="7" :min="1" :marks="marks"> </el-slider>
                    </div>
                </div>
                <div class="output-box">
                    <div class="title">已制卡列表</div>
                    <textarea class="output" readonly v-model="output" ref="outputEl"></textarea>
                </div>
            </div>
            <div class="footer-box">
                <button class="btn" @click="back()">返回</button>
                <button class="btn stop selected"  v-if="!isStopMakeCard && isContinuity" @click="stopCard()">停止制卡</button>
                <button class="btn" :class="{selected:!isInProgress}" v-else @click="businessCard(true)">连续制卡</button>
                <button class="btn" :class="{selected:!isInProgress}" @click="businessCard()">制卡</button>
            </div>
        </div>
        <div class="shade" v-if="isInProgress"></div>
    </div>
</template>

<script>
export default {
    name:'businessCardMember',
    data(){
        return {
            interval:1,
            /**跳过指定数字 */
            num1:'',
            /**跳过指定数字 */
            num2:'',
            /**跳过指定数字 */
            num3:'',
            marks:{1:'1秒',2:'2秒',3:'3秒',4:'4秒',5:'5秒',6:'6秒',7:'7秒',8:'8秒'},
            /**内卡号 */
            snr:"",
            /**卡号 */
            cardNo:'',
            /**是否将内卡号作为外卡号 */
            isSNRInCardNo:false,
            /**输出内容 */
            output:"",
            /**是否在制卡中 */
            isInProgress:false,
            /**是否停止制作卡片 */
            isStopMakeCard:true,
            /**点击连续制卡 */
            isContinuity:false,
            keyBoardInput:undefined,
            /**卡类数据*/
            crmCardKind:[],
            /**选中的卡 */
            selectCard:undefined
        }
    },
    watch:{
        num1(newVue){
            if(newVue!=""){
                this.$refs.num2.focus();
            }
            this.num1=this.num1.replace(/[^0-9]/g,'').substr(0,1);
        },
        num2(newVue){
            if(newVue!=""){
                this.$refs.num3.focus();
            }
            this.num2=this.num2.replace(/[^0-9]/g,'').substr(0,1);
        },
        num3(){
            this.num3=this.num3.replace(/[^0-9]/g,'').substr(0,1);
        },
        cardNo(){
            this.cardNo=(this.cardNo+"").replace(/[^A-z0-9]/g,'').substr(0,20);
        },
        snr(newVal){
            this.snr=(newVal+"").replace(/[^A-z0-9]/g,'').substr(0,20);
            if(this.selectCard?.CardType==0 && this.isSNRInCardNo){//磁条卡
                this.cardNo=this.snr;
            }
        },
        isSNRInCardNo(newVal){
            if(newVal){
                if(this.selectCard?.CardType==0){//磁条卡
                    if(this.snr) this.cardNo=this.snr;
                    else this.snr=this.cardNo;
                }else if(this.selectCard?.CardType==2){//明华(M1)
                    this.cardNo='';
                }
                
            }
        },
        output(){
            this.$nextTick(()=>{
                 this.$refs.outputEl.scrollTop=this.$refs.outputEl.scrollHeight;
            })
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.$refs.cardNo.focus();
        })
        /** 获取卡类列表的*/
        this.$httpAES.post("Bestech.CloudPos.GetCardKinds",{}).then((d)=>{
            console.log(d)
            if(d.ResponseHeader.ResultCode==0){
                this.crmCardKind=d.ResponseBody||[];
            }else{
                this.crmCardKind=[];
                this.$message.error(d.ResponseHeader.ResultDesc);
            }
        }).catch((e)=>{
            this.crmCardKind=[];
            this.$message.error('获取卡类数据失败：'+e);
            console.log('获取卡类数据失败：'+e);
        })
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /*刷新当前页面 */
        reload() {
            this.$forceUpdate();
        },
        /**禁用点击虚拟键默认事件 */
        mousedownKeyBoard(e){
            e.preventDefault();
        },
        /*input 联动虚拟键盘 */
        cheageKeyBoard(val){
            if(this.keyBoardInput){
                let value=this.keyBoardInput.value+"";
                if(val>=0){
                    value= value+val;
                }else if(val=='-1' && value.length>0){
                    value= value.substr(0,value.length-1);
                }
                if(this.keyBoardInput==this.$refs.cardNo){
                    this.cardNo=value
                }else if(this.keyBoardInput==this.$refs.snr){
                    this.snr=value;
                }else if(this.keyBoardInput==this.$refs.num1){
                    if(val=='-1'){
                        this.num1="";
                    }else{
                        this.num1=val+"";
                        this.$refs.num2.focus();
                    }
                }else if(this.keyBoardInput==this.$refs.num2){
                    if(val=='-1'){
                        this.num3="";
                    }else{
                        this.num2=val+"";
                        this.$refs.num3.focus();
                    }
                }else if(this.keyBoardInput==this.$refs.num3){
                    
                    if(val=='-1'){
                        this.num3="";
                    }else{
                        this.num3=val+"";
                    }
                }
            }
        },
        /**input失去焦点时 */
        inputBlur(e){
            if(this.keyBoardInput==e.target){
                this.keyBoardInput=undefined;
            }
        },
        /**input获得焦点时 */
        inputFocus(e){
            this.keyBoardInput=e.target;
        },
        /**选卡 */
        clickCard(item){
            this.selectCard=item;
            if(item.CardType==2){//明华(M1)
                this.snr="";
                if(this.isSNRInCardNo){
                    this.cardNo='';
                }
            }else if(item.CardType==0){//磁条卡
                if(this.isSNRInCardNo){
                    if(this.snr) this.cardNo=this.snr;
                    else this.snr=this.cardNo;
                }
            }
        },
        /**制卡 */
        businessCard(isContinuity){
            if(!this.selectCard){
                this.$message.warning('请选择卡类');
                return ;
            }
            if(this.selectCard.CardType==0){//磁条卡
                if(!this.snr){
                    this.$message.warning('请输入内卡号');
                    return ;
                }
                if(!this.cardNo){
                    this.$message.warning('请输入外卡号');
                    return ;
                }
                if(isContinuity && !/^[0-9]+$/.test(this.cardNo)){//连续制卡
                    this.$message.warning('连续制卡外卡号只能是纯数字!');
                    return ;
                }
                if(isContinuity && !/^[0-9]+$/.test(this.snr)){//连续制卡
                    this.$message.warning('连续制内卡号只能是纯数字!');
                    return ;
                }
            }else if(this.selectCard.CardType==2){//明华(M1)
                if(!this.isSNRInCardNo && !this.cardNo){
                    this.$message.warning('请输入外卡号');
                    return ;
                }
            }
            
            //遮罩 
            this.isInProgress=true;
            //开始制卡    是否停止制卡
            this.isStopMakeCard=false;
            this.isContinuity=isContinuity;
            if(isContinuity){//连续制卡
                let make=()=>{
                    this.makeCard(()=>{
                        if(!this.isStopMakeCard){
                            setTimeout(()=>{
                                if(this.isStopMakeCard){
                                    this.isInProgress=false;
                                    return;
                                }
                                this.createCardNo();
                                make();
                            },this.interval*1000);
                        }else{
                            this.isInProgress=false;
                        }
                    })
                }
                make();
            }else{
                this.makeCard(()=>{
                    this.isInProgress=false;
                    this.isStopMakeCard=true;
                })
            }
            
        },
        /**生成卡号 */
        createCardNo(){
            if(!this.isSNRInCardNo){
                this.cardNo=this.increment(this.cardNo);
                if(this.selectCard.CardType==0){
                    this.snr=this.cardNo;
                }
            }else if(this.selectCard.CardType==0){//磁条卡
                this.cardNo=this.snr=this.increment(this.snr);
            }
        },
        /**自增 */
        increment(str){
            let arr=str.split("");
            for(let i=arr.length-1;i>=0;i--){
                let num=parseInt(arr[i])
                let isBo=false;
                do{
                    num++;
                    if(num>9){
                        num=0;
                    }else{
                        isBo=true;
                    }
                }
                while(num===parseInt(this.num1) || num===parseInt(this.num2) || num===parseInt(this.num3))
                arr[i]=num;
                if(isBo) break; 
            }
            return arr.join("");
        },
        /**制卡过程 */
        makeCard(successFun){
            if(this.selectCard.CardType==2){//明华(M1)
                /**获取内卡号 */
                this.redCardSNR(()=>{
                    //请求制卡接口
                    this.makeActualCard(data=>{
                        //制卡
                        this.$webBrowser.makeCardMHM1({
                            cardID:data.Card_AutoID,cardNo:this.cardNo
                        }).then((d)=>{
                            if(d){
                                if(d.state!=0){
                                    this.isInProgress=false;
                                    this.isStopMakeCard=true;
                                    this.output+=this.cardNo+"制卡失败："+d.message+"\r\n";
                                }else{
                                    this.output+=this.cardNo+"制卡成功"+"\r\n";
                                    if(typeof(successFun)=="function") successFun();
                                }
                            }
                        })
                    })
                })

            }else if(this.selectCard.CardType==0){//磁条卡
                // this.output+=this.cardNo+"制卡成功"+"\r\n";
                // if(typeof(successFun)=="function") successFun();
                //请求制卡接口
                this.makeActualCard((d)=>{
                    console.log(d);
                    this.output+=this.cardNo+"制卡成功"+"\r\n";
                    if(typeof(successFun)=="function") successFun();
                });
            }
        },
        /** 请求制卡接口 */
        makeActualCard(successFun){
            this.$httpAES.post("Bestech.CloudPos.MakeActualCard",{
                Card_No:this.cardNo,//外卡号
                Card_SN:this.snr,//内卡号
                CardKind_AutoID:this.selectCard.CardKind_Autoid//卡类id
            }).then((d)=>{
                console.log(d)
                if(d.ResponseHeader.ResultCode==0){
                    if(typeof(successFun)=="function") successFun(d.ResponseBody);
                }else{
                    this.isInProgress=false;
                    this.isStopMakeCard=true;
                    this.output+=this.cardNo+"制卡失败："+d.ResponseHeader.ResultDesc+"\r\n";
                }
            }).catch((e)=>{
                this.isInProgress=false;
                this.isStopMakeCard=true;
                this.output+=this.cardNo+"制卡失败："+e+"\r\n";
            })
        },
        /**获取内卡号 */
        redCardSNR(successFun){
            this.$webBrowser.redCardSNR().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.snr=d.data;
                        if(this.isSNRInCardNo){
                            this.cardNo=d.data;
                        }
                        if(typeof(successFun)=="function") successFun();
                    }else{
                        this.isInProgress=false;
                        this.isStopMakeCard=true;
                        this.output+=this.cardNo+"制卡失败："+d.message+"\r\n";
                    }
                }
            })
        },
        /**停止制卡 */
        stopCard(){
            this.isStopMakeCard=true;
        }

    }
}
</script>

<style lang='scss'>
@import "./businessCardMember.scss";
</style>